import userModel from './userModel'
import siteModel from './siteModel'

interface Stores {
    userStore: userModel
    siteStore: siteModel
}

export default function mobxStores(): Stores {
    return {
        userStore: new userModel(),
        siteStore: new siteModel(),
    }
}
