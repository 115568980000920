import Http from '@/servers/http'
import globalApi from '@/servers/globalApi'
// import type { GetSite } from './interface'
import { makeAutoObservable, toJS } from 'mobx'
import { makePersistable, isHydrated } from 'mobx-persist-store'
import type { BusinessInfoType, SiteData } from '@/types'
import { setCookie, setLocalStorage } from '@/storage'
// import { Toast } from 'antd-mobile'
import { assertCurrentOrigin, getDomain, setMerchantSiteData } from '@/utils/urlUtils'
import { findSiteData, getSiteConfigData } from '@wotu/wotu-components'
// import { getPortalCodeFromUrl } from '@/utils/getPortalCodeFromUrl'
import changeIco from '@/utils/changeIco'
import { Toast } from 'antd-mobile'
import { cloneDeep } from 'lodash'
import { history } from 'umi'
// import { Toast } from 'antd-mobile'

// import { setCookie } from '@/storage'

/**站点信息类型 */
type CurrentSiteData = {
    portalData?: BusinessInfoType | undefined
    data?: SiteData | undefined
    time?: number
}

/**站点信息类型 */
type PortalData = Record<string, BusinessInfoType>
export default class SiteStore {
    public siteData: CurrentSiteData = {
        data: undefined,
        time: 0,
    }

    public portalData: PortalData = {}
    public siteAvatar = ''

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
        makePersistable(this, {
            name: 'gatewayH5SiteStore', // 保存的name，用于在storage中的名称标识，只要不和storage中其他名称重复就可以
            properties: ['siteData', 'portalData', 'siteAvatar', 'hasRequestPortal'], // 要保存的字段，这些字段会被保存在name对应的storage中，注意：不写在这里面的字段将不会被保存，刷新页面也将丢失：get字段例外。get数据会在数据返回后再自动计算
            storage: window.localStorage, // 保存的位置：看自己的业务情况选择，可以是localStorage，sessionstorage
        })
    }

    /**
     * 获取站点信息
     */
    getSiteConfigByDebounce = async (callback: () => void) => {
        if (this.siteData.time) {
            if (new Date().getTime() - this.siteData.time > 600000) {
                await this.getSiteConfig()
            }
            callback?.()
        } else {
            await this.getSiteConfig()
            callback?.()
        }
    }

    /**
     * 获取门户信息
     */
    getPortalData = async (orgCode: string) => {
        if (!orgCode) return
        const portalData: any = await Http(
            `${globalApi.businessVisit}`,
            'get',
            {
                organizationCode: orgCode,
            },
            { repeatFilter: false },
        )
        if (!portalData) {
            Toast.show('机构信息有误!')
            history.replace('/404')
            return
        } else {
            let tempPortalData = cloneDeep(this.portalData)
            tempPortalData = { ...tempPortalData, [orgCode]: portalData }
            this.portalData = tempPortalData
            changeIco(tempPortalData)
        }
    }

    /**
     * 获取站点信息
     */
    getSiteConfig = async () => {
        const siteData = (await getSiteConfigData({
            domainUrl: getDomain(),
            all: true,
        })) as unknown as SiteData

        if (siteData === null) {
            Toast.show('找不到站点!')
            return
        }

        const merchantPath =
            findSiteData(siteData, 'merchantUserDomain', { findKey: 'baseInfo' }) || ''
        if (assertCurrentOrigin(merchantPath)) {
            setMerchantSiteData(siteData)
        }
        const { baseInfo = { alias: '' }, sid } = siteData || {}
        setLocalStorage('SID', sid)
        const { alias } = baseInfo
        setCookie('ALIAS', alias)
        const tempSiteConfig: CurrentSiteData = {
            time: new Date().getTime(),
            data: siteData,
        }
        this.updateSiteData(tempSiteConfig)
        this.getSiteAvatar(tempSiteConfig)
    }

    /**
     * 更新站点信息
     */
    updateSiteData = (params: CurrentSiteData) => {
        console.log('更新站点信息前', toJS(this.siteData))
        this.siteData = {
            ...this.siteData,
            ...params,
        }

        console.log('更新站点信息后', toJS(this.siteData))
    }

    /**
     * 获取站点配置的默认头像
     */

    getSiteAvatar = (params: CurrentSiteData) => {
        const { data } = params || {}
        const { configList } = (data || []) as SiteData
        configList?.map(item => {
            if (item?.key === 'avatar') {
                this.siteAvatar = item?.value || ''
            }
        })
    }

    // 当页面load完成，get数据计算完成之后，isHydrated会置为true
    // 从本地同步数据到store是异步的 所以最好在页面useEffect添加store.isHydrated依赖 在里面判断isHydrated为true 之后再去做一些数据处理 防止数据错误
    get isHydrated() {
        return isHydrated(this)
    }
}
