const api = {
    upload: '/auth/resource/file/upload',
    getUserInfo: '/auth/user/v1/info',
    getUserOrg: '/auth/user/v1/organization_list',
    getUserGroup: '/auth/user/v1/group_list',
    getSiteConfig: '/auth/backend/site/config',
    // 获取资源方关联的对应站点信息
    getDomainBySiteData: '/auth/backend/site/detail_by_domain',
    loginOut: '/auth/user/v1/logout',
    updateOrg: '/auth/user/v1/update_choice_organization',
    orgDetail: '/auth/organization/organization_base_info',
    // 访问门户
    businessVisit: '/business/portal/one',
    defaultIdentity: '/auth/user_identity/default_identity',

    // 获取公钥
    getPublicKey: '/auth/user/v1/public_key',
    /**  通过自定义别名获取机构code  */
    getPortalCodeByAlias: '/business/portal/find_custom_domain/',
    /**  当前用户是否属于当前私密门户  */
    isPortalUser: 'auth/user/v1/check_privacy_portal_permissions',
}
export default api
