import type { AxiosPromise, Method, AxiosResponse } from 'axios'
import axios from 'axios'
import GlobalLoading from '@/components/Loading'
import kingUrl from '../kingUrl'
import { Toast } from 'antd-mobile'
import type { RequestConfig } from './interface'
import { getCookie, delCookie, setCookie } from '@/storage'
import type { AnyObj } from '@/types'
import { history } from 'umi'
import selfHistory from '@/utils/selfHistory'
const myHistory = selfHistory()

const GlobalLoadingDom = GlobalLoading()

// 请求重复过滤
const requestSet: Set<string> = new Set()

// 动画加载队列
let loadingTimer: number | null = null

const Http = axios.create({
    baseURL: kingUrl,
    headers: {
        'Content-Type': 'application/json',
    },
})

/**
 * 获取请求标识
 * @param config
 * @returns
 */
const getRequestId = (config: RequestConfig): string => {
    let { url, data = {}, params = {} } = config

    // response config 的data或params是被序列号后的数据
    typeof data === 'string' ? (data = JSON.parse(data)) : ''
    typeof params === 'string' ? (params = JSON.parse(params)) : ''

    return encodeURIComponent(`${url}${JSON.stringify(data)}${JSON.stringify(params)}`)
}

/**
 * 显示加载动画
 * @param delay
 */
const showLoading = (delay: number) => {
    loadingTimer !== null && window.clearTimeout(loadingTimer)

    loadingTimer = window.setTimeout(() => {
        GlobalLoadingDom.show('正在上传中...')
    }, delay)
}

/**
 * 隐藏动画加载
 */
const hideLoading = () => {
    if (loadingTimer !== null) {
        window.clearTimeout(loadingTimer)

        // 关闭加载动画
        GlobalLoadingDom.close()
    }
}

/**
 * 请求拦截器
 */
Http.interceptors.request.use(
    (config: RequestConfig) => {
        const { delayTime = 1000 * 80, url = '' } = config

        // 修改请求超时时间
        config.timeout = delayTime
        // 请求头添加token
        if (getCookie('TOKEN')) {
            config.headers.Authorization = getCookie('TOKEN')
        }

        // 开启过滤空数据(例如：get请求数据查询)
        if (config.filterEmptyData === true) {
            // 过滤get空数据
            if (config.method?.toLowerCase() === 'get') {
                const filterParams: AnyObj = {}

                for (const key in config.params) {
                    const val = config.params[key]

                    val !== '' && (filterParams[key] = val)
                }

                config.params = filterParams
            }
        }

        // 文件上传
        // 单独处理格式转换、超时设置、加载动画
        if (config.headers['Content-Type'] === 'multipart/form-data') {
            const formData = new FormData()

            for (const key in config.data) {
                formData.append(key, config.data[key] as any)
            }

            config.data = formData
            config.timeout = 1000 * 600

            showLoading(delayTime)
        }

        // 重复接口过滤
        if (config.repeatFilter !== false) {
            const requestToken = getRequestId(config as RequestConfig)

            // 拦截重复请求，使其处于Promise<pedding>状态
            if (requestSet.has(requestToken)) {
                return new Promise(() => {})
            }

            // 存储请求拦截回调函数
            requestSet.add(requestToken)
        }

        // 接口请求增加alias别名
        if (ALIAS_ENV !== 'global' && ALIAS_ENV) {
            config.headers['X-Site-Alias'] = ALIAS_ENV
        }

        // 接口请求增加alias别名
        // 本地开发和业务线接口需要带上站点别名
        const needAliasList = ['/exam_main/', '/career_main/']
        const isNeedAlias = Boolean(needAliasList.find(i => url.indexOf(i) > -1))

        if (isNeedAlias) {
            const alias = getCookie('ALIAS') || ''
            if (alias) {
                config.headers['X-Site-Alias'] = alias
            }
        }

        // 接口请求增加org 存在一域名多门户情况 必须从url中取
        let {
            location: { pathname },
        } = history
        let currentAlias = pathname.split('/')?.[1] || ''
        const unCookie = ['403', '404', '500']
        if (!unCookie.includes(currentAlias) && currentAlias) {
            config.headers['X-Site-Org'] = currentAlias
        }

        return config
    },
    error => {
        // 请求错误处理
        // 配置err显示
        Promise.reject(error)
    },
)

Http.interceptors.response.use(
    res => {
        const requestToken = getRequestId(res.config as RequestConfig)

        // 移除已完成的请求，避免占位
        requestSet.delete(requestToken)

        // 清空加载动画
        hideLoading()

        const { status, config, data } = res || {}
        if (status === 200) {
            const { messageCode, message: requestMessage, success } = data || {}
            if (config.url === '/auth/captcha/captcha.jpeg') {
                return Promise.resolve(data)
            }

            // 请求正常 后端代码报错处理
            if (messageCode.slice(0, 1) === '5' && !requestMessage) {
                Toast.show({
                    content: '系统繁忙,请稍后再试!',
                    duration: 3000,
                })
                return Promise.reject('系统繁忙,请稍后再试!')
            }

            if ((config as RequestConfig).form) {
                return Promise.resolve(data)
            } else {
                if (success === true) {
                    return Promise.resolve(data.data)
                } else {
                    Toast.show({
                        content: requestMessage,
                    })
                    return Promise.reject(`${requestMessage}`)
                }
            }
        } else {
            Toast.show({
                content: '系统繁忙',
            })
            return Promise.reject('系统繁忙')
        }
    },
    error => {
        // 清空加载动画
        hideLoading()

        // 响应错误处理
        const { data = {}, status }: { data: any; status: number } = error.response || {}
        const { config } = error

        const requestToken = getRequestId(config)

        // 移除已完成的请求，避免占位
        requestSet.delete(requestToken)

        // 请求超时
        if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
            Toast.show({
                content: '请求超时,请稍后再试!',
                duration: 3000,
            })

            return Promise.reject('请求超时,请稍后再试!')
        }

        // 服务端数据处理异常
        if (String(status).slice(0, 1) === '5') {
            Toast.show({
                content: '系统繁忙,请稍后再试!',
            })
            return Promise.reject('系统繁忙,请稍后再试!')
        }
        let errResponse: any = ''

        switch (status) {
            case 400:
                errResponse = '400'
                break

            case 401:
                delCookie('TOKEN')
                Toast.show({
                    content: '登录已过期，请重新登录',
                    duration: 3000,
                })
                if (window) {
                    setCookie('FROM_URL', location.pathname + location.search)
                    // 从pathname中解析当前的机构code
                    const { pathname } = location
                    const currentAlias = pathname.split('/')?.[1]
                    const unCookie = ['403', '404', '500']

                    let domain
                    if (!unCookie.includes(currentAlias)) {
                        domain = currentAlias
                    }

                    if (domain) {
                        myHistory.replace(
                            `/user-center/user/login?back=${encodeURIComponent(location.href)}`,
                        )
                    }

                    window?.self_store?.userStore?.initStore()
                }
                errResponse = '请先登录'
                break

            case 404:
                errResponse = '404'
                break

            default:
                errResponse = error.response
        }

        if (!config.noMsg) {
            if (Array.isArray(data)) {
                Toast.show({
                    content: data[0].message,
                    duration: 3000,
                })
            } else {
                if (typeof data === 'object') {
                    Toast.show({
                        content: data?.message,
                        duration: 3000,
                    })
                }
            }
        }

        return Promise.reject(errResponse)
    },
)

export default <T, K>(
    url: string,
    method: Method,
    data: T,
    config?: RequestConfig,
): AxiosPromise<K> =>
    Http.request<RequestConfig, AxiosResponse<K>>({
        ...config,
        url,
        method,
        [['get', 'GET'].includes(method) ? 'params' : 'data']: data,
    })
