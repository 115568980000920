// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/root/workspace/fe-business-h5_UVmF/node_modules/.pnpm/@umijs+runtime@3.5.20_react@16.14.0/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__index' */'@/layouts/index.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/403",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__403__index' */'@/pages/403/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/404",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404__index' */'@/pages/404/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/500",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__500__index' */'@/pages/500/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/:alias",
        "routes": [
          {
            "path": "/:alias/about-us",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__alias__about-us__index' */'@/pages/[alias]/about-us/index.tsx'), loading: LoadingComponent})
          },
          {
            "path": "/:alias/admin/preview",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__alias__admin__preview__index' */'@/pages/[alias]/admin/preview/index.tsx'), loading: LoadingComponent}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/admin'), loading: LoadingComponent})]
          },
          {
            "path": "/:alias/admin/user/login",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__alias__admin__user__login__index' */'@/pages/[alias]/admin/user/login/index.tsx'), loading: LoadingComponent})
          },
          {
            "path": "/:alias/admin/user/register",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__alias__admin__user__register__index' */'@/pages/[alias]/admin/user/register/index.tsx'), loading: LoadingComponent})
          },
          {
            "path": "/:alias/home",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__alias__home__index' */'@/pages/[alias]/home/index.tsx'), loading: LoadingComponent})
          },
          {
            "path": "/:alias/micro",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__alias__micro__index' */'@/pages/[alias]/micro/index.tsx'), loading: LoadingComponent})
          },
          {
            "path": "/:alias/mine",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__alias__mine__index' */'@/pages/[alias]/mine/index.tsx'), loading: LoadingComponent})
          },
          {
            "path": "/:alias/picture/detail",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__alias__picture__detail__index' */'@/pages/[alias]/picture/detail/index.tsx'), loading: LoadingComponent})
          },
          {
            "path": "/:alias/picture/list",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__alias__picture__list__index' */'@/pages/[alias]/picture/list/index.tsx'), loading: LoadingComponent})
          }
        ],
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__alias___layout' */'@/pages/[alias]/_layout.tsx'), loading: LoadingComponent})
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404__index' */'@/pages/404/index.tsx'), loading: LoadingComponent})
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
