/**
 *   create by feeling on 2020/6/10
 */

import { DotLoading } from 'antd-mobile'
import styles from './index.module.less'

const PageLoading = () => {
    return (
        <div className={styles.loading_content}>
            <DotLoading color="primary" />
        </div>
    )
}

export default PageLoading
