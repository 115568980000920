import { Toast } from 'antd-mobile'
import { makeAutoObservable, runInAction } from 'mobx'
import { makePersistable, isHydrated } from 'mobx-persist-store'
import * as Storage from '@/storage'
import selfHistory from '@/utils/selfHistory'
import type { UserInfo, UserAccount, UserOrgItem, UserGroupItem } from './interface'
import Http from '@/servers/http'
import globalApi from '@/servers/globalApi'
import { delCookie, getCookie } from '@/storage'
import { history } from 'umi'
const myHistory = selfHistory()
export default class UserStore {
    public userAccount: UserAccount | undefined
    public userData: UserInfo | undefined
    public userOrg: UserOrgItem[] = []
    public userGroup: UserGroupItem[] = []
    public currentOrgCode: string | undefined
    // 当前页面是否有tabbar，用于调整content高度
    public hasTabBar: boolean = false
    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
        makePersistable(this, {
            name: 'gatewayH5userStore', // 保存的name，用于在storage中的名称标识，只要不和storage中其他名称重复就可以
            properties: [
                'userData',
                'userAccount',
                'userOrg',
                'currentOrgCode',
                'userGroup',
                'hasTabBar',
            ], // 要保存的字段，这些字段会被保存在name对应的storage中，注意：不写在这里面的字段将不会被保存，刷新页面也将丢失：get字段例外。get数据会在数据返回后再自动计算
            storage: window.localStorage, // 保存的位置：看自己的业务情况选择，可以是localStorage，sessionstorage
        })
        // .then(persistStore => {
        //     console.log('-----UserStore本地化同步----')
        //     console.log(toJS(persistStore.target.userAccount))
        // })
    }

    /**
     * 更新用户基本信息
     */
    update_user = (data: UserInfo) => {
        this.userData = data
    }

    /**
     * 更新当前页面的tabbar状态,是否存在
     */
    update_tab_bar = (status: boolean) => {
        this.hasTabBar = status
    }
    /**
     * 获取当前页面的tabbar高度
     */
    getTabBarHeight = () => {
        return this.hasTabBar ? '64px' : '0px'
    }

    /**
     * 判断用户是否是当前门户用户
     */
    isPortalUser = async (orgCode: string) => {
        if (!orgCode) return
        const isPortalUserBool: any = await Http(
            `${globalApi.isPortalUser}`,
            'post',
            {
                organizationCode: orgCode,
            },
            { repeatFilter: false },
        )
        if (!isPortalUserBool) {
            this.login_out()
        }
    }

    /**
     * 更新用户账号
     */
    update_user_account = async (data: UserAccount) => {
        const { accessToken, tokenExpire = 7 } = data
        this.userAccount = data
        Storage.setCookie('TOKEN', accessToken, tokenExpire)
        await this.get_user_data()
    }

    /**
     * 获取用户信息
     */
    get_user_data = async () => {
        let userData: UserInfo = (await Http(
            globalApi.getUserInfo,
            'get',
            {},
            {
                repeatFilter: false,
            },
        )) as unknown as UserInfo

        let { code } = userData || {}
        Storage.setCookie('USER_CODE', code)

        runInAction(() => {
            this.update_user(userData)
        })
    }

    /**
     * 前往fromUrl
     */
    gotoFrom = () => {
        let fromUrl = ''
        const excludeList = [
            '/',
            '/404',
            '/500',
            '/bind/authenticate',
            '/user/login',
            '/user/forget',
            'user/register',
            '/user-center/user/login',
        ]
        let userType = getCookie('USER_TYPE')
        switch (userType) {
            case 'org':
                fromUrl = getCookie('PREVIEW_URL') || ''
                delCookie('PREVIEW_URL')
                break
            case 'user':
                fromUrl = getCookie('FROM_URL') || ''
                delCookie('FROM_URL')
                break

            default:
                myHistory.replace('/mine')
                return
        }
        if (fromUrl.includes('http')) {
            window.location.href = decodeURIComponent(decodeURIComponent(fromUrl || ''))
        } else {
            if (fromUrl && !excludeList?.includes(fromUrl)) {
                history.replace(fromUrl)
            } else {
                myHistory.replace('/mine')
            }
        }
    }

    /**
     * 退出登录
     */
    login_out = () => {
        Http(globalApi.loginOut, 'get', {}).then(() => {
            let userType = getCookie('USER_TYPE')
            if (userType === 'org') {
                myHistory.push('/admin/user/login')
            } else {
                Toast.show({
                    content: '退出成功',
                })
                myHistory.replace(
                    `/user-center/user/login?back=${encodeURIComponent(location.href)}`,
                )
            }
            this?.initStore()
            Storage.delCookie('TOKEN')
            this.userData = undefined
        })
    }

    /**
     * 数据重置
     */
    initStore = () => {
        this.userAccount = undefined
        this.userData = undefined
        this.userOrg = []
        this.userGroup = []
        this.currentOrgCode = undefined
    }

    // 当页面load完成，get数据计算完成之后，isHydrated会置为true
    // 从本地同步数据到store是异步的 所以最好在页面useEffect添加store.isHydrated依赖 在里面判断isHydrated为true 之后再去做一些数据处理 防止数据错误
    get isHydrated() {
        return isHydrated(this)
    }
}
