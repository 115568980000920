// import { getCookie } from '@/storage'
import { history } from 'umi'

type HistoryObj = {
    length: any
    action: any
    location: any
    createHref: any
    push?: any
    replace?: any
    go: any
    goBack: any
    goForward: any
    block: any
    listen: any
}

function selfHistory() {
    const { length, action, location, createHref, go, goBack, goForward, block, listen } = history
    const current: HistoryObj = {
        length,
        action,
        location,
        createHref,
        go,
        goBack,
        goForward,
        block,
        listen,
    }
    current.push = (url: string) => {
        // const domain = getCookie('SELECT_ORG_CODE')
        // 从pathname中解析当前的机构code
        let { pathname } = location
        let currentAlias = pathname.split('/')?.[1] || ''
        const unCookie = ['403', '404', '500']
        let domain = ''
        if (!unCookie.includes(currentAlias)) {
            domain = currentAlias
        }
        if (domain) {
            history.push(`/${domain}${url}`)
        } else {
            history.push(url)
        }
    }
    current.replace = (url: string) => {
        // const domain = getCookie('SELECT_ORG_CODE')
        // 从pathname中解析当前的机构code
        let { pathname } = location
        let currentAlias = pathname.split('/')?.[1] || ''
        const unCookie = ['403', '404', '500']
        let domain = ''
        if (!unCookie.includes(currentAlias)) {
            domain = currentAlias
        }
        if (domain) {
            history.replace(`/${domain}${url}`)
        } else {
            history.replace(url)
        }
    }
    return current
}

export default selfHistory
