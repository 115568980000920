import type { KEYS_H5 } from '@wotu/wotu-components'
import {
    getCookie as h5GetCookie,
    setCookie as h5SetCookie,
    delCookie as h5DelCookie,
    delNormalCookie as h5DelNormalCookie,
    clearCookie as h5ClearCookie,
} from '@wotu/wotu-components'

/**
 * 获取 cookie
 * @param key
 * @returns
 */
export const getCookie = (key: keyof typeof KEYS_H5) => {
    return h5GetCookie(key, 'h5')
}

/**
 * 写入 cookie
 * @param key
 * @param data
 * @param expires
 */
export const setCookie = (key: keyof typeof KEYS_H5, data: any, expires: number = 7) => {
    return h5SetCookie(key, data, expires, 'h5')
}

/**
 * 删除
 * @param key
 */
export const delCookie = (key: keyof typeof KEYS_H5) => {
    return h5DelCookie(key, 'h5')
}

/**
 * 普通删除
 * @param key
 */
export const delNormalCookie = (key: string) => {
    return h5DelNormalCookie(key)
}

/**
 * 删除所有cookie
 */
export const clearCookie = () => {
    return h5ClearCookie()
}
