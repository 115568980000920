import 'antd-mobile/es/global'
import type { ReactNode } from 'react'
import React from 'react'
import { Provider } from 'mobx-react'
import mobxStores from './stores/globalStore'
import { ConfigProvider } from 'antd-mobile'
import zhCN from 'antd-mobile/es/locales/zh-CN'
import MyEmpty from '@/components/Empty'
import selfHistory from '@/utils/selfHistory'
const masterHistory = selfHistory()
import { getPortalCodeFromUrl } from './utils/getPortalCodeFromUrl'

const stores = mobxStores()

const MobxProvider = (props: any) => (
    <Provider {...stores} {...props}>
        <ConfigProvider locale={zhCN} renderEmpty={MyEmpty} {...props} />
    </Provider>
)

export function rootContainer(container: ReactNode[], opts: any) {
    return React.createElement(MobxProvider, opts, container)
}

const getApps = new Promise<void>(resolve => {
    const getEntry = (name: string, env: string) => {
        const appMap: Record<string, any> = {
            'exam-fe-app': {
                local: 'http://localhost:9001',
                default: 'https://static.zpimg.cn/dev/exam-fe-app/index.html',
                test: 'https://static.zpimg.cn/dev/exam-fe-app/index.html',
                pre: 'https://static.zpimg.cn/pre/exam-fe-app/index.html',
                pro: 'https://static.zpimg.cn/pro/exam-fe-app/index.html',
            },
            'fe-user-h5': {
                local: 'http://localhost:9009',
                default: '/subApp/fe-user-h5/index.html',
                test: '/subApp/fe-user-h5/index.html',
                pre: '/subApp/fe-user-h5/index.html',
                pro: '/subApp/fe-user-h5/index.html',
            },
            'career-fe-app': {
                local: 'http://localhost:8000',
                default: '/subApp/career-fe-app/index.html',
                test: '/subApp/career-fe-app/index.html',
                pre: '/subApp/career-fe-app/index.html',
                pro: '/subApp/career-fe-app/index.html',
            },
            'fe-enroll-h5': {
                local: 'http://localhost:9020',
                default: '/subApp/enroll-center/index.html',
                test: '/subApp/enroll-center/index.html',
                pre: '/subApp/enroll-center/index.html',
                pro: '/subApp/enroll-center/index.html',
            },
            'fe-exam-h5': {
                local: 'http://localhost:9088',
                default: '/subApp/exam-center/index.html',
                test: '/subApp/exam-center/index.html',
                pre: '/subApp/exam-center/index.html',
                pro: '/subApp/exam-center/index.html',
            },
            'fe-sign-h5': {
                local: 'http://localhost:9018',
                default: '/subApp/sign-center/index.html',
                test: '/subApp/sign-center/index.html',
                pre: '/subApp/sign-center/index.html',
                pro: '/subApp/sign-center/index.html',
            },
        }

        if (env === 'local') {
            return appMap?.[name]?.[env]
        } else {
            return appMap?.[name]?.[BUILD_ENV]
        }
    }
    resolve({
        // 注册子应用信息
        apps: [
            {
                name: 'exam-fe-app',
                entry: getEntry('exam-fe-app', RUN_ENV),
            },
            {
                name: 'fe-user-h5',
                entry: getEntry('fe-user-h5', RUN_ENV),
            },
            {
                name: 'career-fe-app',
                entry: getEntry('career-fe-app', RUN_ENV),
            },
            {
                name: 'fe-enroll-h5',
                entry: getEntry('fe-enroll-h5', RUN_ENV),
            },
            {
                name: 'fe-exam-h5',
                entry: getEntry('fe-exam-h5', RUN_ENV),
            },
            {
                name: 'fe-sign-h5',
                entry: getEntry('fe-sign-h5', RUN_ENV),
            },
        ],
        routes: [
            {
                path: `/:alias/assessment`,
                microApp: 'exam-fe-app',
            },
            {
                path: `/:alias/user-center`,
                microApp: 'fe-user-h5',
            },
            {
                path: `/:alias/career`,
                microApp: 'career-fe-app',
            },
            {
                path: `/:alias/enroll-center`,
                microApp: 'fe-enroll-h5',
            },
            {
                path: `/:alias/exam-center`,
                microApp: 'fe-exam-h5',
            },
            {
                path: `/:alias/sign-center`,
                microApp: 'fe-sign-h5',
            },
        ],
        sandbox: true, //是否启用沙箱
        prefetch: 'all', //是否启用预加载
        // 解决职培职培组件无法播放的问题
        excludeAssetFilter: (url: string) => {
            const microAppAssetFilterWhiteWords = [
                'turing.captcha.qcloud.com/TCaptcha.js',
                'aliplayer-min.css',
                'aliplayer-min.js',
                'apis.map.qq',
            ]
            return microAppAssetFilterWhiteWords.some(word => {
                return url.includes(word)
            })

            // if (url.includes('aliplayer-min.js') || url.includes('aliplayer-min.css')) {
            //     return true
            // }
            // return false
        },
    } as any)
})

export const qiankun = getApps.then(res => res)

export function useQiankunStateForSlave() {
    const { userStore, siteStore } = stores || {}
    return {
        tag: 'portal',
        userStore,
        siteStore,
        getOrganizationCode: getPortalCodeFromUrl,
        gatewayUserStore: userStore,
        gatewaySiteStore: siteStore,
        masterHistory,
    }
}
